<template>
  <div>
    <v-dialog v-model="addDataCorrectionDialog" persistent width="450px">
      <v-toolbar dense class="gradient-bg2 elevation-0" height="40px">
        <v-toolbar-title class="white--text"
          >Add Data Correction</v-toolbar-title
        >
        <v-spacer />

        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-form ref="form">
          <v-card flat class="mx-3">
            <v-row no-gutters class="mt-5">
              <v-autocomplete
                label="Select Member *"
                v-model="filtermember"
                :items="filtermembers"
                :rules="rules"
                dense
                :search-input.sync="searchL"
                placeholder="Start typing to Search"
                item-text="user_name"
                item-value="user_id"
                hide-no-data
                hide-selected
                outlined
              >
              </v-autocomplete>
            </v-row>
            <v-row no-gutters>
              <v-autocomplete
                label="Select Location *"
                v-model="filterlocation"
                :rules="rules"
                :items="filterlocations"
                :search-input.sync="searchF"
                hide-no-data
                hide-selected
                hint="Start typing to Search"
                dense
                :disabled="isLoading == true"
                item-text="location_name"
                item-value="location_id"
                outlined
              >
              </v-autocomplete>
            </v-row>
            <v-row no-gutters class="mx-0 my-n1">
              <v-col cols="12" md="6" sm="12" lg="6" xl="6" xs="12">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  color="#f4813f"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormatted"
                      dense
                      outlined
                      class="mr-2"
                      persistent-hint
                      :disabled="isLoading == true"
                      label="Select Date *"
                      color="#f4813f"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :min="minmonth"
                    :max="new Date().toISOString().substr(0, 10)"
                    v-model="date"
                    color="#f4813f"
                    scrollable
                    @input="check_data()"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="#f4813f" @click="modal = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="#f4813f"
                      @click="$refs.dialog.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6" sm="12" lg="6" xl="6" xs="12">
                <v-dialog
                  ref="MondayStartTime"
                  v-model="MondayStartDialog"
                  :return-value.sync="MondayStartTime"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="MondayStartTime"
                      label="Select Time *"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      :rules="rules"
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-time-picker
                    v-model="MondayStartTime"
                    format="24hr"
                    :max="timedisable == true ? getCurrentTime() : null"
                    :disabled-minutes="
                      timedisable == true ? getDisabledMinutes() : []
                    "
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="MondayStartDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.MondayStartTime.save(MondayStartTime)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-select
                outlined
                v-model="Comments"
                label="Comments *"
                dense
                :items="DataItems"
                auto-grow
              ></v-select>
            </v-row>
          </v-card>
          <v-card-text class="mt-n5" align="center">
            <v-btn
              color="#D97D54"
              class="gradient-bg white--text"
              :loading="Loading"
              small
              @click="validateform"
              ><v-icon class="mr-2 ml-n2">mdi-table-edit</v-icon>
              ADD DATA CORRECTION
            </v-btn>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { list_users_master, getLocationsForOrg } from "@/graphql/queries.js";
import { data_correction_list_or_edit } from "@/graphql/mutations.js";
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
export default {
  props: {
    addDataCorrectionDialog: Boolean,
  },
  components: {
    SnackBar,
    Overlay,
  },
  data: (vm) => ({
    memberItems: [],
    DataItems: [],
    SnackBarComponent: {},
    overlay: false,
    searchL: null,
    Comments: "",
    searchF: null,
    rules: [(v) => !!v || "required"],
    filtermembers: [],
    datetime: new Date(),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    timedisable: false,
    isLoading: false,
    Loading: false,
    filtermember: "",
    filterlocation: "",
    filterlocations: [],
    array1: [],
    date: new Date().toISOString().substr(0, 10),
    MondayStartTime: "",
    modal: false,
    MondayStartDialog: false,
    selectedDate: "",
    minmonth: "",
  }),
  computed: {
    computedDateFormatted: {
      get() {
        return this.formatDate(this.date);
      },
      set(value) {
        // Implement the logic to handle changes to the 'computedDateFormatted' property
        // For example, you can parse the formatted date back to the original format and update 'this.date'
        this.date = this.parseDate(value);
      },
    },
  },
  watch: {
    addDataCorrectionDialog(val) {
      if (val == true) {
        this.selectedDate = this.get_date(
          this.$store.getters.get_org_details[0].organisation_created_on
        );

        (this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
          "YYYY-DD-MM"
        )),
          (this.date = new Date().toISOString().substr(0, 10));
        this.check_data();
        this.MondayStartTime = "";
        this.overlay = true;
        this.get_members_by_status();
        this.list_locations1();
        this.list_data_correction();
      }
    },
    date(val) {
      this.dateFormatted = this.formatDate(val);
      this.date = new Date(val).toISOString().substr(0, 10);
    },
  },
  methods: {
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    check_data() {
      var currentDate = new Date();

      var year = currentDate.getFullYear();
      var month = currentDate.getMonth() + 1;
      var day = currentDate.getDate();

      // Pad the day and month with leading zeros if necessary
      var formattedDay = day < 10 ? "0" + day : day;
      var formattedMonth = month < 10 ? "0" + month : month;

      // Create the formatted date string
      var formattedDate = formattedDay + "/" + formattedMonth + "/" + year;
      if (this.computedDateFormatted === formattedDate) {
        this.timedisable = true;
        this.MondayStartTime = "";
      } else {
        this.timedisable = false;
        this.MondayStartTime = "";
      }
    },
    getCurrentTime() {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      return `${hours}:${minutes}`;
    },
    getDisabledMinutes() {
      const now = new Date();
      const currentMinutes = now.getMinutes();
      const disabledMinutes = [];
      for (let i = currentMinutes + 1; i < 60; i++) {
        disabledMinutes.push(i);
      }
      return disabledMinutes;
    },
    async list_locations1() {
      try {
        await API.graphql(
          graphqlOperation(getLocationsForOrg, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              location_status: "ACTIVE",
            },
          })
        ).then((res) => {
          this.array1 = res.data.getLocationsForOrg.data;
        });

        this.array1.forEach((element) => {
          this.filterlocations.push({
            location_name: element.loc_name,
            location_id: element.loc_id,
          });
        });
      } catch (err) {
        console.log("errrrr", err);
        this.filterlocations = [];
        this.overlay = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
      }
    },
    validateform() {
      if (this.$refs.form.validate()) {
        this.callDataCorrection();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked * are mandatory",
        };
      }
    },
    callDataCorrection() {
      this.Loading = true;
      var new_time = Math.floor(
        new Date(
          this.date.split("-")[0],
          this.date.split("-")[1] - 1,
          this.date.split("-")[2],
          this.MondayStartTime.split(":")[0],
          this.MondayStartTime.split(":")[1]
        ).getTime() / 1000
      );
      const path = "/prznce_data_correction";
      const v = {
        headers: {
          "X-API-Key": "Qqmh14V2u475aSAxf0fdc4jdMENPIha13KiagpKT",
        },
        body: {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          user_id: this.filtermember,
          location_id: this.filterlocation,
          user_email_id: this.$store.getters.get_useremail,
          swipe_time_stamp: new_time,
          comment: this.Comments,
          command: "dataCorrections",
        },
      };
      API.post("prznce_data_correction", path, v)
        .then((response) => {
          if (response.errorType == "Error") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: response.errorMessage,
            };
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.Message,
            };
          }
          // console.log(response);
          this.Loading = false;

          this.$emit("call_data_correction");
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
        })
        .catch((error) => {
          console.log(error.response);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
          this.Loading = false;
          this.dialog = false;
        });
    },
    async get_members_by_status() {
      try {
        this.filtermembers = [];
        let result = await API.graphql(
          graphqlOperation(list_users_master, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              action_type: "MEMBERS",
            },
          })
        );
        var response = JSON.parse(result.data.list_users_master);
        if (response.Status == "SUCCESS") {
          var array = response.data;
          array.forEach((element) => {
            this.filtermembers.push({
              user_name: element.user_name,
              user_id: element.user_id,
            });
          });
        } else {
          this.filtermembers = [];
        }
        // this.filtermembers =
        //   result.data.list_users_master.ActiveMembers.length != 0
        //     ? result.data.list_users_master.ActiveMembers
        //     : [];
        this.overlay = false;
      } catch (err) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        console.log("error", err);
        this.overlay = false;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    close_dialog() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit("close_data_correction_dialog");
    },
    async list_data_correction() {
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(data_correction_list_or_edit, {
            input: {
              action_type: "LIST",
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );
        this.overlay = false;
        this.isLoading = false;
        this.DataItems = JSON.parse(
          result.data.data_correction_list_or_edit
        ).Items[0].data_comments;
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.overlay = false;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
